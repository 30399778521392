@if (field && field.tile) {
  @if (!field.hidden && (field.condition | async)) {
    @if (isClickable) {
      <button class="tile" (click)="clickHandler()">
        <ng-container [ngTemplateOutlet]="linkContent" />
      </button>
    } @else {
      <div class="tile">
        <ng-container [ngTemplateOutlet]="linkContent" />
      </div>
    }
    <span class="tile-label" [innerHTML]="field.value | async"></span>
    <ng-template #linkContent>
      @if (field.tile.image) {
        <img [src]="field.tile.image" [alt]="field.value" />
      } @else if (field.tile.icon) {
        <mat-icon>{{ field.tile.icon }}</mat-icon>
      }
    </ng-template>
  }
  <div class="hidden">
    <app-form-prefix [field]="field" />
  </div>
}
