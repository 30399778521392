import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { LinkData } from "src/app/components/global/prefixes/templates/template-link/template-link.component";
import { ROUTES_CONFIG } from "src/config/routes.config";
import { ActionResponse } from "src/interfaces/post-request/post-request";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { FormPrefixComponent } from "../../../global/prefixes/containers/form-prefix/form-prefix.component";
import { FormField } from "../form-field";

@Component({
  selector: "app-form-field-tile",
  imports: [MatIconModule, AsyncPipe, NgTemplateOutlet, FormPrefixComponent],
  templateUrl: "./form-field-tile.component.html",
  styleUrl: "./form-field-tile.component.less",
})
export class FormFieldTileComponent implements OnInit {
  @Input({ required: true })
  public field: FormField | null;

  public isClickable: boolean;

  private router: Router;
  private http: HttpService;
  private application: ApplicationService;

  public constructor() {
    this.field = null;
    this.isClickable = false;

    this.router = inject(Router);
    this.http = inject(HttpService);
    this.application = inject(ApplicationService);
  }

  public ngOnInit(): void {
    if (!this.field || !this.field.tile) {
      throw new Error();
    }
    this.isClickable = this.checkIsClickable(this.field);
  }

  public clickHandler(): void {
    if (this.field) {
      switch (this.field.template) {
        case "lnk":
        case "ln2":
        case "ln3":
        case "ln4":
          {
            const link = this.getLink(this.field);
            if (link) this.router.navigate(["/app/content", link.link]);
          }
          break;
        case "lna":
          {
            const link = this.getLink(this.field);
            if (link) this.sendAction(link);
          }
          break;
        default:
          console.warn("Unhandled tile press for prefix", this.field.template);
          break;
      }
    }
  }

  private checkIsClickable(field: FormField): boolean {
    return ["lnk", "ln2", "ln3", "ln4", "lna"].includes(field.template);
  }

  private getLink(field: FormField): LinkData | null {
    if (typeof field.extras === "object" && field.extras !== null && "links" in field.extras) {
      const links = field.extras["links"];
      if (typeof links === "object" && links !== null && Array.isArray(links) && links.length > 0) {
        const link = links.shift();
        if (link) {
          return link as LinkData;
        }
      }
    }
    return null;
  }

  private async sendAction(route: LinkData): Promise<void> {
    const id = route.link;
    const res = await this.http.send<ActionResponse>(ROUTES_CONFIG.actionurl, {
      FFWDActionID: id,
    });
    this.application.onMessage(JSON.parse(res.postActionAsJSON).messages);
  }
}
